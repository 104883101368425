<template>
  <g id="g17"
    >
    <g id="g324" class="dut_components">
      <TestPointView
        ref="testpoint_view"
        :testpointPlot="testpointPlot"
        :renderScale="renderScale"
        :sliderScale="sliderScale"
        :svgScale="1"
        :x_offset="2187"
        :y_offset="10493"
        :width="9213"
        :height="8456"
        :probeElement="probeElement"
        :btnRenderSide="btnRenderSide"
        :showRefs="showRefs"
        :searchRefString="searchRefString"
        :refSlider="refSlider"
        key="dev_260_tp"
        @toggleHoverText="toggleHoverText"
      />
      <GuidePinView
        ref="guidepin_view"
        :guidepinPlot="guidePins"
        :renderScale="renderScale"
        :sliderScale="sliderScale"
        :svgScale="1"
        :x_offset="2187"
        :y_offset="10493"
        :width="9213"
        :height="8456"
        :btnRenderSide="btnRenderSide"
        :showRefs="showRefs"
        :searchRefString="searchRefString"
        :refSlider="refSlider"
        key="dev_260_gp"
        @toggleHoverText="toggleHoverText"
      />
    </g>
    <Dev260Top v-show="showPlate && sideToggle != 2" />
    <Dev260Bottom v-show="showPlate && sideToggle != 1" />
  </g>
</template>
<script>
import interact from "interactjs";
export default {
  name: "Dev260",
  props: {
    testpointPlot: Array,
    guidePins: Array,
    renderScale: Number,
    svgScale: Number,
    sliderScale: Number,
    probeElement: Object,
    sideToggle: Number,
    btnRenderSide: Number,
    showRefs: Boolean,
    showPlate: Boolean,
    searchRefString: String,
    refSlider: Number,
  },
  components: {
    TestPointView: () => import("./TestPointView.vue"),
    GuidePinView: () => import("./GuidePinView.vue"),
    Dev260Top: () => import("./Dev260Top.vue"),
    Dev260Bottom: () => import("./Dev260Bottom.vue"),
  },
  data() {
    return {
      position: {
        x: 0,
        y: 0,
      },
    };
  },
  methods: {
    activateDragAndDrop() {
      interact(`.dut_components`).draggable({
        onmove: (event) => {
          this.position.x += event.dx * 12;
          this.position.y -= event.dy * 12;
          event.target.style.transform = `translate(${this.position.x}px, ${this.position.y}px)`;
        },
      });
    },
    setTransform(selection) {
      this.$refs.testpoint_view &&
        this.$refs.testpoint_view.setTransform(selection);
    },
    toggleHoverText(payload) {
      this.$emit("toggleHoverText", payload);
    },
  },
  mounted() {
    this.activateDragAndDrop();
  },
};
</script>
